<template>
  <!-- id : contents : (S) -->
  <div id='contents' >

    <div class="sub-visual-wrap" :class="returnClass">
      <div class="inner-base-box">
        <h2 class="h2">{{ menuName }}</h2>
        <ol>
          <li style="background: none;">홈</li>
          <li>{{ menuName }}</li>
          <li>{{ currentTabMenu.menuName }}</li>
        </ol>
      </div>
    </div>

    <!-- class : content-wrap : (S) -->
    <section class="content-wrap st-center">
      <div class="inner-base-box">
          <div class="tab-menu-box">
          <a href="javascript:;" class="btn-menu " :class="{open : this.$store.state.tapOpen === true === true}" @click="openTab()">탭열기버튼</a>
          <span class="t-txt">MENU</span>
        </div>
        <div class="secPageTabBox " :class="{open : this.$store.state.tapOpen === true}">
          <div class="btn-go-back mb-only"><a href="#" @click.prevent="goback">이전페이지</a></div>
          <div class="sub-tab-menu-box mb40 "  style="height:auto;" :class="returnClass">
            <a href="javascript:;" v-for="(tab) in tabs" :class="{on:currentTabMenu.boardId === tab.boardId}" @click="onClickTab(tab)" :key="tab.boardId">{{ tab.menuName }}</a>
          </div>
          <div class="sub-tab-mb-title">
            <a href="javascript:;" v-for="(tab) in tabs" :class="{on:currentTabMenu.menuName==tab.menuName}" @click="onClickTabMobile()" :key="tab.boardId">{{ tab.menuName }}</a>
          </div>
          <div class="sub-tab-list-box" :class="{on:this.tablistOpen === true}">
            <a href="javascript:;" v-for="(tab, index) in tabs" :class="{on:currentTabMenu.menuName==tab.menuName}" @click="onClickTab(tab, index)" :key="tab.boardId">{{ tab.menuName }}</a>
          </div>
        </div>
      </div>
      <!-- class : main-wrap (S) -->
      <div class="main-wrap">
        <div class="inner-base-box">
          <!-- subpage-title-box (S) -->
          <div class="subpage-title-box st-noline" v-if="componentStatus =='write'">
            <h3 class="h3">글 작성 <span class="txt-s">{{ boardName }}</span></h3>
          </div>
          <!-- tb-01 (S) -->
          <div class="tb-01 st-w mb-none">
            <table >
              <colgroup><col width="150px"></colgroup>
              <tbody>
              <tr>
                <td colspan="2" class="st-title">
                  <div class="st-write-title-box">
                    <select name="" class="input-st1 st-selectbox" v-model="article.categoryId">
                      <option v-for="category in board.boardCategories" :key="category.categoryId" :value="category.categoryId">{{category.categoryName}}</option>
                    </select>
                    <input type="text" class="input-st1" placeholder="제목을 입력하세요." v-model="article.title" maxlength="40">
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="tb-01 st-w st-line-box">
            <table v-if="article.articleAttachments && article.articleId && board.boardType === 'TYPE_PHOTO'">
              <colgroup><col width="150px" ></colgroup>
              <tbody>
              <tr class="st-date-tb" >
                <th class="al-mid"> 대표이미지 설정 </th>
                <td>

                  <v-row>
                    <div
                        v-for="img in article.articleAttachments.filter(this.filterThumb)"
                        :key="img.fileId"
                        style="padding: 5px 5px 5px 5px;"
                    >
                      <v-card
                          class="mx-auto"
                          style="cursor: pointer; overflow: hidden"
                          max-width="100"
                          max-height="100"
                          @click="setMainImage(img.fileId)"
                      >
                        <v-icon color="yellow" v-if="img.mainImage" style="color: yellow; position: absolute">mdi-image-album</v-icon>
                        <img :src="`/attachment/${img.fileContextPath}${img.fileServerName}`" />
                      </v-card>
                    </div>
                  </v-row>

                </td>
              </tr>
              </tbody>
            </table>

<!--            <editor-tiptap :val="article.contents" ref="contents"/>-->
<!--            <editor-component-->
<!--                ref="contents"-->
<!--                :val="article.contents"-->
<!--                :uploaded="fileMeta"-->
<!--                @callback-upload="uploadImage"-->
<!--                label="게시판 내용" />-->
            <editor-quill :val="article.contents" ref="contents"/>

            <table>
              <colgroup><col width="150px"></colgroup>
              <tbody>
              <tr class="st-date-tb" v-if="boardId==='567'">
                <th class="al-mid">계좌정보</th>
                <td>
                  <!-- 2021-03-08 add -->
                  <div class="input-info-box">
                    <ul>
                      <li>
                        <label>은행명</label>
                        <input type="text" class="input-st1 wd160" placeholder="은행명 입력" v-model="article.bank">
                      </li>
                      <li>
                        <label>예금주</label>
                        <input type="text" class="input-st1 wd160" placeholder="예금주명 입력" v-model="article.accountHolder">
                      </li>
                      <li>
                        <label>계좌번호</label>
                        <input type="text" class="input-st1 wd160" placeholder="계좌번호 입력" v-model="article.accountNumber" @input="onInputEvent">
                      </li>
                    </ul>
                  </div>
                  <!-- 2021-03-08 add -->
                </td>
              </tr>
              <tr class="st-date-tb" v-if="boardId==='567'">
                <th class="al-mid">회원정보</th>
                <td>
                  <!-- 2022-02-28 mod -->
                  <div class="mem-search-box">
                    <div class="target-user-box off" ref="targetUserBox" >
                      <span ref="targetUserName" class=""> <em v-if="article.targetUser">{{article.targetUser.name}}</em> 
                      </span>   
                      <button type="button" aria-label="clear icon" class="v-icon notranslate v-icon--link mdi mdi-close theme--light" @click="deleteTargetUser"></button> 
                    </div>
                    
                    <button  @click="onClickUser()" class="btn-file-up" >검색</button>
                  </div>
                  <!-- 2022-02-28 add -->
                </td>
              </tr>
              <tr class="st-file-tb" v-if="board.hasAttachment">
                <th style="padding-top: 40px;">첨부파일</th>
                <td>
                  <v-col class="mgr" style="padding: 0 0" >
                    <v-file-input style="padding-top: 0px;margin-top: 10px;width: 95%"
                        v-model="files"
                        label="파일 선택"
                        multiple
                        prepend-icon="mdi-paperclip"
                        @change="appendFile"
                        @click:clear="clearAllFile"
                    >
                      <template v-slot:selection="{ text, file }">
                        <v-chip
                            small
                            label
                            color="primary"
                            close @click:close="removeFile(file)"
                        >
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>

                    <div v-if="article.articleAttachments && this.article.articleAttachments.length>0">
                      <h4>첨부 완료 파일</h4>
                      <v-chip v-for="f in attachedFiles" v-bind:key="f.fileId" class="ma-2" close @click:close="removeAttachedFile(f)">
                        <v-tooltip bottom v-if="f.contentType.indexOf('image') === 0">
                          <template v-slot:activator="{ on, attrs }">
                            <span
                                v-bind="attrs"
                                v-on="on"
                            >
                              <v-icon>mdi-image</v-icon>
                              {{ f.fileRealName }}
                            </span>
                          </template>

                          <span>
                            <v-img :src="`/attachment/${f.fileContextPath}${f.thumbnail}`" max-height="200" max-width="200"/>
                          </span>
                        </v-tooltip>
                        <span v-else>{{ f.fileRealName }}</span>
                      </v-chip>

                      <div class="btnStyleBx" style="padding-top: 10px">
                        <div class="btnCommon justify-center">

                          <v-btn class="vBtn" color="gray" dark @click="deleteAttachedFiles" style="margin-right: 5px">
                            <v-icon left>mdi mdi-delete</v-icon>
                            첨부 완료 파일 전체 삭제
                          </v-btn>

                          <v-btn class="vBtn" color="#fafafa" @click="resetAttachedFiles">
                            <v-icon left>mdi mdi-reload</v-icon>
                            취소
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </td>
              </tr>
              <tr class="st-date-tb" v-if="isGroup && board.boardType === 'TYPE_PHOTO' ">
                <th class="al-mid"> 게시물 복사 </th>
                <td>
                  <input type="checkbox" id="Copy" class="chk-st1" v-model="copyTargetBoard" true-value="568" :false-value="null"><label for="Copy">커뮤니티 > 포토게시판</label>
                </td>
              </tr>
              <tr class="st-date-tb" v-if="board.hasNotice && (authNotice || authAdmin)">
                <th class="al-mid"><input type="checkbox" id="Notice" class="chk-st1" v-model="article.notice" @change="onClickSettings"><label for="Notice">상시 설정</label></th>
                <td >
                  <div class="date-select-box" v-if="board.hasPeriod">
                    <input type="checkbox" id="Period" class="chk-st1" v-model="setPeriod" @change="onClickSettings"><label for="Period">기간 설정</label>
                      <DgDatePicker format="YYYY-MM-DD" v-model="article.startDt" class="input-st1-period" style="padding: 0 0;width:200px !important" placeholder="yyyy-mm-dd" :disabled="!setPeriod"/>
                      <span class="line">~</span>
                      <DgDatePicker format="YYYY-MM-DD" v-model="article.endDt" clearable class="input-st1-period" style="padding: 0 0;width:200px !important" placeholder="yyyy-mm-dd" :disabled="!setPeriod"/>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!-- tb-01 (E) -->

          <div class="board-btn-box al-center st-two"><a href="javascript:;" class="st2" @click="onClickCancel">취소</a><a href="javascript:;" @click="onClickSave">저장</a></div>
        </div>
      </div>
    </section>
    <CommonPopup v-if="memberPopupOpen" :tap="popupTab" @click:targetUserSelect="targetUserInfo" @click:bgDimClose="popupMemberList" ></CommonPopup>
  </div>
  <!-- id : contents : (E) -->
</template>

<script>
import articleApi from "@/api/ArticleApi";
import boardApi from '@/api/BoardApi'
import DgDatePicker from '@/views/component/DgBaseDatepicker'
import _ from 'lodash';

import EditorQuill from "@/views/component/EditorQuillComponent";
import menuApi from "@/api/MenuApi";

import CommonPopup from "@/views/include/common_popoup";

export default {
  name: "NoticeWrite",
  components:{
    DgDatePicker,
    EditorQuill,
    CommonPopup,
  },
  computed:{
    authNotice(){
      let auth = false;
      if((this.permissionMap?.['NOTICE']??[]).filter(x => (this.$store.state?.user?.roles??[]).map(r => parseInt(r)).includes(x)).length != 0){
        auth = true;
      }
      return auth;
    },
    authAdmin(){
      let auth = false;
      if((this.permissionMap?.['ADMIN']??[]).filter(x => (this.$store.state?.user?.roles??[]).map(r => parseInt(r)).includes(x)).length != 0){
        auth = true;
      }
      return auth;
    },
    isGroup() {
      return this.group?.groupId??false
    }
  },
  mounted(){
    //console.log("3.마운티드실행");
    //console.log(this.article);
    if(this.article.articleId != null) {
      //console.log("2. 회원정보 불러오기 실행")
      this.initArticleInfo();
    }
    //회원정보 체크 
    // if(this.boardId == "567"){ 
    //   console.log("this.article.targetUser");
    //   console.log(this.article.targetUser);
    //   if(this.article.targetUser === null){
    //     this.$refs.targetUserBox.className = "target-user-box off";
    //   }else{
    //     this.$refs.targetUserBox.className = "target-user-box";
    //   }
    // }
    
  },
  data() {
    return {
      copyTargetBoard:false,
      editorOption: {
        placeholder: '내용을 입력해주세요.',
        theme: 'snow'
      },
      returnClass:'st-division',
      ////
      menuName:'',
      board:{},
      ////
      articles:[],
      activeItem: null,
      currentTabMenu: {},
      tabs: [],

      parentMenu: null,
      boardType: 'TYPE_GENERAL',
      boardName: '',
      componentStatus: 'write',
      menuUrl:null,
      status: '',
      saveClick:false,
      targetUser : null, 

      memberList: false,
      popupTab: 'memberlist',
      boardId: 0,
      group:{groupId: null},

      article: {
        articleId: null,
        title: null,
        contents: null,
        writer: null,
        notice: false,
        startDt: null,
        endDt: null,
        thumbnail:null,
        targetUser:{}, 
        bank:null,
        accountHolder:null,
        accountNumber:null
      },
      setPeriod: false,

      fileMeta:{},
      files:[],
      selectedFiles:[],
      attachedFiles:[],
      addAttachedFiles :[],

      permissionMap:null,
      subdivisionId :  427,
      clubId : 428,
      menuId : null,

      memberPopupOpen: false,
      tablistOpen:false,
    }
  },
  watch:{
    isOpen(e){
      if(!e){
        this.memberList=false
      }
    }
  },
  created(){
    console.log("1. ct실행")
    this.getReady();
  },
  methods:{
    setMainImage(fileId) {
      this.article.mainImageId = fileId;
      this.article.articleAttachments.forEach(a => a.mainImage = false);
      this.article.articleAttachments.filter(a => a.fileId === fileId)[0].mainImage = true;
    },
    async getReady(){
      this.boardId = this.$route.params.id //this.$attrs.boardId//
      this.parentMenu = this.$route.query.upper
      this.article.articleId = this.$route.query.at //this.articleId //

      if(this.parentMenu==427){
        this.returnClass='st-division'
      }else if(this.parentMenu==428){
        this.returnClass='st-club'
      }else if(this.parentMenu==431){
        this.returnClass='st-community'
      }
      let newMenuMap = this.$store.state.menuInfoData  == null ? {}  : this.$store.state.menuInfoData  ;

      if(newMenuMap[this.parentMenu] == null){
        await menuApi.getMenu(this.parentMenu).then(data=>{
          newMenuMap[this.parentMenu] = data;
          this.$store.commit('setMenuInfoData', newMenuMap);
        });
      }

      this.initMenuInfo( newMenuMap[this.parentMenu]);

      let childs = this.$store.state.menuInfoData[this.parentMenu].childMenus;

      const that = this;
      const found = childs.find(element => element.boardId == that.boardId);

      let newboardMap =  this.$store.state.boardInfoData == null ? {} : this.$store.state.boardInfoData ;

      if(found)
      {
        for(let i=0; i <  childs.length ; i++) {
          const item = childs[i];

          if (item.boardId != null && newboardMap[item.boardId] == null) {
            await boardApi.get(item.boardId).then(data => {
              newboardMap[Number(data.boardId)] = data;
              this.$store.commit('setBoardInfoData', newboardMap);
            });
          }
        }
      }
      else{
        await boardApi.get(this.boardId).then(data => {
          newboardMap[Number(data.boardId)] = data;
          this.$store.commit('setBoardInfoData', newboardMap);
        });
      }

      this.initBoardInfo(newboardMap[this.boardId]);
      

    },
    initMenuInfo(data) {
      this.menuId = data.menuId;
      this.menuName=data.menuName
      this.tabs = data.childMenus;    //ce분과, it분과, 디스플레이분과 etc.
      this.currentTabMenu = this.tabs.find(data=> data.childMenus.find(d=> d.boardId==this.boardId))

      this.boardName = this.currentTabMenu.childMenus.find(d=>d.boardId==this.boardId).menuName
    },
    initBoardInfo(data) {
      this.board = data;
      let defaultCategory = {
        'categoryId': null,
        'categoryName': "전체",
        'orderNo': 0
      }
      if(this.board.boardCategories && this.board.boardCategories.length > 0) {
        this.board.boardCategories = [defaultCategory, ...this.board.boardCategories.filter(c => c.categoryId !== null)]
      }else{
        this.board.boardCategories = [defaultCategory]
      }
      this.article.categoryId=null;

      this.permissionMap = this.board.boardPermissions.reduce((result, item) => {
        result[item.id.functionType] = [...result[item.id.functionType]??[], item.id.roleId];
        return result;
      }, {});

      this.group = data.group;
    },
    filterThumb(value){
      console.log(value)
      if(value.contentType.indexOf('image') === 0 && value.deleted === false){
        return true;
      }else {
        return false;}
    },

    filefilters(value){
      if(value.fileRealName.includes('모바일 첨부파일_') || value.fileRealName.includes('에디터 첨부파일_')){
        return false;
      }else return true;
    },
    reverseFilefilters(value){
      if(value.fileRealName.includes('모바일 첨부파일_') || value.fileRealName.includes('에디터 첨부파일_')){
        return true;
      }else return false;
    },
    initArticleInfo(){
      articleApi.get(this.boardId, this.article.articleId).then(data => {
        this.article = data;
        this.targetUser = data.targetUser;
        this.article.targetUser = data.targetUser;
        this.article.categoryId = data.categoryId
        if(data.startDt != null && data.endDt != null){
          this.setPeriod = true
        }
        this.attachedFiles = _.cloneDeep(data.articleAttachments.filter(this.filefilters));
        this.addAttachedFiles = _.cloneDeep(data.articleAttachments.filter(this.reverseFilefilters))
        //회원정보체크
        if(this.boardId == "567"){ 
          if(this.article.targetUser === null){
            this.$refs.targetUserBox.className = "target-user-box off";
          }else{
            this.$refs.targetUserBox.className = "target-user-box";
          }
        }
      });
    },
    onClickTab(tab){
      let url = tab.url;
      if(tab.menuType === 'TYPE_BOARD') {
        url = `/board/${tab.boardId}`;
      }
      this.$router.push({path: url+`?upper=${this.parentMenu}&menu=${tab.menuId}`}).catch(()=>{});
    },
    thumbnailSuccess(file, response) {
      this.article.thumbnail = response.saveName;
      const acceptedFiles = this.$refs.articleThumbnailDropzone.getAcceptedFiles();
      if(acceptedFiles && acceptedFiles.length > 1)
        this.$refs.articleThumbnailDropzone.removeFile(acceptedFiles[0]);
    },
    thumbnailRemove() {
      this.article.thumbnail = null;
    },
    appendFile() {
      this.files = [...new Set([
        ...this.selectedFiles,
        ...this.files
      ])]
      this.selectedFiles = _.cloneDeep(this.files);
    },
    clearAllFile() {
      this.files = [];
      this.selectedFiles = [];
    },
    removeFile(removeFile) {
      this.files = this.files.filter(f => f !== removeFile);
      this.selectedFiles = _.cloneDeep(this.files);
    },
    removeAttachedFile(attachedFile) {
      this.attachedFiles = this.attachedFiles.filter(f => f.fileId !== attachedFile.fileId);
    },
    resetAttachedFiles() {
      this.attachedFiles = this.article.articleAttachments;
    },
    deleteAttachedFiles() {
      this.attachedFiles = []
    },
    uploadImage(f) {
      articleApi.uploadImage(this.boardId, f).then(res => {
        // console.log(res);
        this.fileMeta = res;
        console.log('//////////'+JSON.stringify(res))
      });
    }, 
    onClickSave() {
      if(this.saveClick){
        return;
      }
      this.article.contents = this.$refs.contents.getValue();

      if(this.article.title == '' || this.article.title == null) {
        return alert('제목을 입력해주세요.')
      }else if(this.article.contents == '' || this.article.contents == null){
        return alert('내용을 입력해주세요.')
      }

      this.article.boardId = this.boardId;
      // this.article.contents = this.$refs.contents.getValue();
      this.article.writer= this.$store.state?.user?.username??'';
      this.article.createdBy= this.$store.state?.user?.userid??'';

      /* 회원정보 저장 추가 */
      if(this.targetUser){
        this.article.targetUser = this.targetUser;
        this.article.targetUser.userHasRoles =(this.targetUser?.userHasRoles??[]).map(x=>x.id.roleId);
        this.article.targetUser.userHasGroups = (this.targetUser?.userHasGroups??[]).map(r=>r.id.groupId);

      }else{
        this.article.targetUser =null;
      }

      if(!this.setPeriod){
        this.article.startDt=null;
        this.article.endDt=null;
        this.article.always=true;
      }else{
        if(this.article.startDt == null || this.article.endDt == null){
          return alert('상시 설정 기간을 입력해주세요.')
        }else if(this.article.startDt>this.article.endDt){
          return alert('상시 설정 종료일이 시작일보다 빠를 수 없습니다.')
        }else{
          this.article.always=false;
        }
      }
      this.article.articleAttachments = this.attachedFiles;
          for(const value of this.addAttachedFiles){
            this.article.articleAttachments.push(value)
          }
      this.saveClick = true;
      //console.log("this.article");
      //console.log(this.article);
      articleApi.save(this.article, this.files).then(data => {
        if (data) {
          if(this.article.articleId) {
            alert("수정되었습니다.");
          } else {
            alert("등록되었습니다.");
          }

          if (this.copyTargetBoard) {
            articleApi.copyTo(data.articleId, this.copyTargetBoard).then(() => {
              this.copyTargetBoard = null;
            });
          }
          
          this.$router.go(-1);
        } else {
          alert("등록 중 오류가 발생했습니다.");
          this.saveClick = false;
        }
      });
    },
    onClickCancel(){
      if(confirm('작성을 정말 취소하시겠습니까? \n작성한 내용이 저장되지 않습니다.')){
        if(this.article.articleId) {
          //this.$emit('click:title','detail',this.articleId)
          this.$router.go(-1);
        } else {
          this.$router.go(-1);
        }
      }
    },
    onClickSettings(e){
      if(e.target.id === 'Notice' && !this.article.notice){
        this.setPeriod = false
      }else if(e.target.id === 'Period' && this.setPeriod) {
        this.article.notice = true
      }
    },
    onInputEvent(e){
      this.article.accountNumber = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
    },
      /*탭 오픈*/
    openTab(){
     this.$store.commit('setTapOpen')
    },
    /* 회원 검색 팝업 */
    onClickUser(){ 
      this.popupMemberList(true)
    }, 
    popupMemberList(e) {
      this.memberPopupOpen = e 
      this.$emit('click:bgDim', this.memberPopupOpen)
    },
    targetUserInfo(targetUserInfo) {
      this.targetUser = targetUserInfo;
      //console.log("this.targetUser load");
      //console.log(this.targetUser);
      this.$refs.targetUserBox.className = "target-user-box";
      this.$refs.targetUserName.innerText = targetUserInfo.name;
    },
    deleteTargetUser(){
      this.targetUser = null;
      this.$refs.targetUserName.innerText = "";
      this.$refs.targetUserBox.className = "target-user-box off";
    },
    onClickTabMobile(){
      if(this.tablistOpen){
        this.tablistOpen = false;
      }else{
        this.tablistOpen = true;
      }
    },
    goback(){
      this.$router.go(-1);
    }
    
  }

}
</script>

<style>
.ql-editor{
  min-height:200px;
}
.btn-top{display: none}
</style>